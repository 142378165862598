import circleAnimation from './circle.json'
import _ from 'lodash'

export const replaceAnimationCircleColor = (circleJson, [r, g, b]) => {
  const copy = _.cloneDeep(circleJson)
  const output = _.set(copy, [
    'layers', '0', 'shapes', '0', 'it', '1', 'c', 'k'
  ], [
    r/255, g/255, b/255, 1
  ])
  return output
}

export const campaignIgnoreSites = ['VV']
export const campaignMenuVisibleSites = ['FEW']

export const merchantNames = {
  'STS': 'Save The Storks',
  'VV': 'Verity\'s Village',
  'FEW': 'For Every Woman',
}

export const siteLinkUrl = {
  STS: 'https://savethestorks.com',
  VV: 'https://veritysvillage.com',
  FEW: 'https://foreverywoman.org',
}
export const sitePhone = {
  STS: '(866) 639-0479',
  VV: '(555) 123-4567',
}
export const siteGeneralBackgroundImage = {
  STS: utmCampaign => `/server/assets/images/${utmCampaign === '' || typeof utmCampaign === 'undefined' ? 'header-main.jpg' : ('campaigns/' + utmCampaign + '.png')}`,
  FEW: () => '/server/assets/images/header-main.png',
}
export const siteGeneralBackgroundImageStyle = {
  VV: {
    minHeight: 250,
    width: '100%', 
    position: 'relative',
    backgroundImage: 'url(/server/assets/images/header-main.jpeg)',
    backgroundPosition: '91% 50%',
    backgroundSize: 'cover',
  },
  FEW: {
    width: '100%',
    position: 'relative',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  EMA: {
    minHeight: 250,
    width: '100%', 
    position: 'relative',
    backgroundImage: 'url(/server/assets/images/header-main.jpeg)',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    marginTop: '-100px',
    zIndex: 1,
  },
  OPH: {
    minHeight: 250,
    width: '100%', 
    position: 'relative',
    backgroundColor: '#37B8CB',
  },
}
export const siteSuccessBackgroundImage = {
  STS: 'https://savethestorks.com/wp-content/uploads/2021/08/media_blue_swash_rev.png',
  VV: 'https://veritysvillage.com/wp-content/uploads/2021/03/evan-kirby-101570-unsplash-1024x683.jpg',
  FEW: 'https://host.foreverywoman.org/wp-content/uploads/2022/07/ultrasound_hero.jpg',
  EMA: '',
  OPH: '',
}
export const sitesWithTrapezoid = ['STS']
export const sitesWithPhoneNav = ['STS']
export const siteLogoStyles = {
  STS: { maxWidth: 120 },
  VV: { maxWidth: 280 },
  FEW: { },
  EMA: { maxWidth: 280 },
  OPH: { maxWidth: 195.12 },
}

export const siteMasterDomainDict = {
  STS: `https://savethestorks.com`,
  VV: 'https://veritysvillage.com',
  FEW: 'https://foreverywoman.org',
  EMA: 'https://www.everymothersadvocate.org/',
  OPH: 'https://optionspartners.org',
}
export const siteImpactReportDict = {
  STS: '/wp-content/uploads/2021/11/Save-the-Storks-Midyear-Impact-Report-2021.pdf',
  VV: '',
  FEW: '',
  EMA: '',
  OPH: '',
}
export const siteEcfaLogoUrl = {
  STS: 'https://donate.savethestorks.com/images/ECFA-Accredited-150x150.png',
  FEW: 'https://donate.savethestorks.com/images/ECFA-Accredited-150x150.png',
  EMA: '',
  OPH: '',
}

export const siteDisclaimerText = {
  STS: 'Thank you for your support of the mission and programs of Save the Storks. Please note that the board maintains discretion and control over the use of the funds. This discretion is exercised by the board as they approve project the ministry will solicit funds. If the ministry receives a gift for a project that is not approved by the board, the ministry has authority to go back to a donor requesting a redesignation toward a current project or to be used for general purposes.'
}
export const siteProcessingFeeText = {
  STS: 'Help Save the Storks receive 100% of your donation!',
  VV: 'Help us receive 100% of your donation!',
  FEW: 'Help us receive 100% of your donation!',
  EMA: 'Help us receive 100% of your donation!',
  OPH: 'Help us receive 100% of your donation!'
}

export const siteDefaultErrMsg = {
  general: {
    // STS: 'We are in the process of transferring to a new credit card processor. If you encounter any issues with your donation please call 866-639-0479, ext. 1, M-F 8a-5p MST, or email the team at info@savethestorks.com.',
    STS: 'We have run into an error processing your donation.  If you continue to encounter an issue with your donation please call 866-639-0479, ext. 1, M-F 8am - 5pm MST, or email the team at info@savethestorks.com.',
    VV: 'We have run into an error processing your donation. If you continue to encounter an issue with your donation please email the team at VeritysVillage@gmail.com.',
    FEW: 'We have run into an error processing your donation.',
    EMA: 'We have run into an error processing your donation.',
  },
  plaid: {
    STS: 'Bank Account Error: Please select Bank Account (ACH) and retry your bank account numbers or to complete your donation please call 866-639-0479, ext. 1, M-F 8a-5p MST, or email the team at info@savethestorks.com.',
    VV: 'Bank Account Error: Please select Bank Account (ACH) and retry your bank account numbers or to complete your donation please email the team at VeritysVillage@gmail.com.',
    FEW: 'Bank Account Error: Please select Bank Account (ACH) and retry your bank account numbers.',
  }
}

export const siteSuccessImage = {
  STS: '/server/assets/images/success.jpeg',
  VV: '/server/assets/images/success.png',
  FEW: '/server/assets/images/success.jpeg',
  EMA: '/server/assets/images/success.jpeg',
  OPH: '/server/assets/images/success.jpeg',
}
export const siteThankYouText = {
  STS: 'Thank you for supporting Save the Storks!',
  VV: 'Thank you for supporting Verity\'s Village!',
  FEW: 'Thank you for supporting For Every Woman and empowering women with options for real choice.',
  EMA: 'Thank you for supporting For Every Mother&#039;s Advocate.',
  OPH: 'Thank you for supporting For Options For Her.',
}
export const siteSignUpButtonText = {
  FEW: 'Create an Account',
}
const circleAnimationVV = replaceAnimationCircleColor(circleAnimation, [235, 83, 127])
const circleAnimationFEW = replaceAnimationCircleColor(circleAnimation, [213, 88, 137])
export const siteCircleAnimationDict = {
  STS: circleAnimation,
  EMA: circleAnimation,
  VV: circleAnimationVV,
  FEW: circleAnimationFEW,
  OPH: circleAnimationFEW,
}
export const siteThankYouTextStyle = {
  STS: { fontFamily: 'Montserrat', color: '#0d5e7d' },
  VV: { color: 'black' },
  FEW: { color: 'black' },
  EMA: { color: 'black' },
  OPH: { color: 'black' },
}

export const donationTypeNames = {
  'yearly': 'Annually',
  'monthly': 'Monthly',
  'one-time': 'One-Time',
}
export const sitesWithDonateDescription = ['STS', 'FEW', 'OPH']
