export const inspirations = [
  'Biking for Babies',
  'Unplanned Movie',
  'Blogpost',
  'Email',
  'Google Search',
  'Kirk Cameron/Living Room Reset',
  'American Assoc of Christian Counselors (AACC)',
  'News/Radio/TV',
  'Referral from Friend or Family',
  'Social Media',
  'Other',
  'Heartbeat Video 2020',
]

export const donationOptions = {
  oneTime: {
    values: ['-', '-', '-', '-'],
    prefix: '',
  },
  monthly: {
    values: [10, 35, 50, 70, 105],
    prefix: '$',
  },
  yearly: {
    values: [420, 840, 1260, 2100],
    prefix: '$',
  }
}

export const crmDict = {
  STS: 'salesforce',
  VV: 'salesforce',
  FEW: 'salesforce',
}

export const MIN_DONATION_PER_ATTENDEE = 35
export const MIN_DONATION = 5

const stepsFlowTypeLayoutTemplate = {
  header: false,
  footer: false,
  flowType: 'steps',
  headline: {
    title: 'Donate Today',
    titleStyle: { fontSize: 48, marginTop: 2 },
    subtitle: 'Help unlock real choice for a vulnerable woman.',
    logo_monthly_style: { height: 80 },
  },
  giftDescriptions: [
    'Future moms need you more than ever on an ongoing basis. You can help women facing unplanned pregnancies choose life and embrace the journey to motherhood... by unlocking access to free, life-saving, pro-mom care - not just today, but 365 days a year.',
    'Join Life Society, a faithful community of monthly givers helping to eliminate obstacles to montherhood.'
  ],
  initialPaymentType: 'card',
  paymentDateMenu: { visible: false },
  roundUpCheckbox: { visible: false, defaultValue: false },
  fundDisclaimer: { visible: false }, // if true of not set it'll rendered by Payment component
  successPage: { 
    surveyVisible: false, 
    redirectButtonText: 'Exit', 
    redirectAction: 'donate', 
    redirectButtonStyle: { color: '#fb534e', marginBottom: 10 },
    thankYouMediaStyle: { position: 'relative', aspectRatio: '9/12', flex: 1, paddingBottom: 10 },
    wrapperStyle: { paddingTop: 0 },
    formContainerStyle: { 
      margin: 'auto', display: 'flex', alignItems: 'center',
      maxWidth: 600, minHeight: 600, backgroundColor: 'white', borderRadius: 5,
    },
    monthlyThankYouVideo: 'https://player.vimeo.com/video/908759899?h=a6b9d5ab72',
  },
  amountImpact: { visible: false },
  paymentMethodMenu: { titleStyle: { fontSize: 48, marginTop: 0, paddingTop: 0 } },
  paymentDetails: { 
    excludedMethods: ['bank_ach_stripe'],
    titleStyle: { fontSize: 48, marginTop: 0, paddingTop: 0 },
    menuBoxStyle: { 
      boxShadow: 'none', 
      padding: 0, 
      border: '1px solid #999',
      marginBottom: 10 
    },
    // menuEntryStyle: { border: 'none', padding: 0 },
    ctaButtonStyle: { width: '100%', marginBottom: 10, maxWidth: 'unset' },
    showInputTitles: true,
    inputSize: 'lg',
    anet: { 
      cardName: { sm: 12 },
      cardNumber: { sm: 12 },
      nameOnAccount: { sm: 12 },
      routingNumber: { xs:6, sm: 6 },
      accountNumber: { xs:6, sm: 6 },
      accountType: { style: 'checkbox' },
    }
  },
  contact: {
    title: 'Contact Information',
    title2: 'Address Information',
    titleStyle: { fontSize: 48, marginTop: 0, paddingTop: 0 },
    billingAddressCheckbox: { visible: false },
    messageInput: { visible: false },
    firstName: { xs:6, sm: 6 },
    lastName: { xs:6, sm: 6 },
    email: { order: 3, sm: 12 },
    phone: { xs: 6, sm: 6 },
    company: { xs: 6, sm: 6 },
    country: { xs: 6, sm: 6, md: 6},
    state: { xs: 6, sm: 6, md: 6},
    city: { xs: 6, sm: 6, md: 6},
    zip: { xs: 6, sm: 6, md: 6},
    showInputTitles: true,
    inputSize: 'lg',
    profileToggle: { visible: false },
  },
  campaignMenu: { position: 'bottom' },
  loginButton: { visible: false },
  receiptBanner: { visible: false },
  mimicState: { visible: false },
  attachCurrentSubscription: false,
}

export const layoutConfigs = {
  '769': {
    ...stepsFlowTypeLayoutTemplate,
    headline: {
      ...stepsFlowTypeLayoutTemplate.headline,
      title_monthly: 'Join Life Society',
      subtitle_monthly: 'And support moms with real choice - every day of the year.',
      logo_monthly: 'https://donate.savethestorks.com/images/2024_LifeSociety_Logo-2.png',
    },
    ...(['one-time', 'monthly', 'yearly'].reduce((acc, cur) => ({
      ...acc,
      [`gift_descriptions_${cur}`]: [
        'Future moms need you more than ever on an ongoing basis. You can help women facing unplanned pregnancies choose life and embrace the journey to motherhood... by unlocking access to free, life-saving, pro-mom care - not just today, but 365 days a year.',
        'Consider joining our Life Society, a faithful community of monthly givers helping to eliminate obstacles to motherhood.'
      ],
    }), {})),
  },
  '813': {
    ...stepsFlowTypeLayoutTemplate,
    headline: {
      ...stepsFlowTypeLayoutTemplate.headline,
      title: 'Join Officer Brandon Tatum and Save the Storks Today!',
      titleStyle: { fontSize: 24 },
      subtitle: 'And support moms with real choice - every day of the year.',
      ...(['one-time', 'monthly', 'yearly'].reduce((acc, cur) => ({
        ...acc,
        [`hero_image_${cur}_style`]: { width: '100%', marginBottom: 5 },
        [`hero_image_${cur}`]: 'https://savethestorks.com/wp-content/uploads/2024/03/brandon-tatum.png',
      }), {})),
    },
    ...(['one-time', 'monthly', 'yearly'].reduce((acc, cur) => ({
      ...acc,
      [`gift_descriptions_${cur}`]: [
        'Future moms need you more than ever on an ongoing basis. You can help women facing unplanned pregnancies choose life and embrace the journey to motherhood... by unlocking access to free, life-saving, pro-mom care - not just today, but 365 days a year.',
        'Consider joining our Life Society, a faithful community of monthly givers helping to eliminate obstacles to motherhood.',
      ],
    }), {})),
    successPage: {
      ...stepsFlowTypeLayoutTemplate.successPage,
      additionalMessage: {
        content: 'You have made a huge life-saving difference with your donation! Thank you! We\'ll be sending you a text with a link for your free Tatum Bus Angel T-shirt.',
        minAmounts: {
          'yearly': 250,
          'monthly': 35,
          'one-time': 250,
        }
      }
    }
    // giftOptions: {
    //   title: 'Shirt Size',
    //   options: [
    //     'Small (Female)',
    //     'Small (Male)',
    //     'Medium (Female)',
    //     'Medium (Male)',
    //     'Large (Female)',
    //     'Large (Male)',
    //     'X-Large (Female)',
    //     'X-Large (Male)',
    //     '2X-Large (Female)',
    //     '2X-Large (Male)',
    //     '3X-Large (Male)',
    //     'I would like to opt out of this gift',
    //   ],
    //   minAmounts: {
    //     'yearly': 250,
    //     'monthly': 50,
    //     'one-time': 250,
    //   }
    // }
  }
}