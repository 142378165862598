import {
  inputDonationAmount,
  setDonationAmount,
  setInitialAmountType,
  setPlans,
} from "actions/optionsActions";
import axiosServer from "modules/axiosServer";
import { charityRunHouseholdUrl } from "modules/urls";
import { reCaptchaReady } from "modules/utils";
import {
  attendeeSchema,
  charityRunSchema,
  transformErrorIntoObject,
} from "modules/validation";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { defaultRequiredFlags } from "./flags";
import { getRecaptchaToken, setRecaptchaUsed } from "./recaptchaSlice";
import { setAccountId, setContactId } from "./rootSlice";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState, Extra } from "src/store";
import type {
  CharityRunState,
  CharityRunContact,
} from "./@types/charityRunSlice";

const initialState: CharityRunState = {
  ready: false,
  confirmed: true,
  attendees: [],
  extraForms: {
    raceType: 'In Person',
    formingOwnTeam: { checked: false, name: '' },
    runningInHornorOf: { checked: false, name: '' },
    additionalInformation: '',
  },
  ...defaultRequiredFlags,
};

export const checkCharityRunValidity = createAsyncThunk<
  boolean,
  void,
  { state: RootState; extra: Extra }
>("charityRun/validateAttendee", async (_, { getState, rejectWithValue }) => {
  const { charityRun } = getState();

  if (!charityRun.ready) return true;

  try {
    const results = await charityRunSchema.validate(charityRun, {
      abortEarly: false,
    });
    return true;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const syncCharityRunWithSalesforce = createAsyncThunk<
  { data: any },
  void,
  { state: RootState; extra: Extra }
>(
  "charityRun/registerHousehold",
  async (_, { dispatch, getState, rejectWithValue, extra }) => {
    const { charityRun, access, recaptcha } = getState();

    try {
      console.log("syncCharityRunWithSalesforce", extra);
      const recaptchaMode = recaptcha.mode
      let token = recaptchaMode === 'v2' ? recaptcha.v2Token : recaptcha.token;
      if (!token || recaptcha.status !== "idle") {
        await dispatch(getRecaptchaToken());
        token = getState().recaptcha[recaptchaMode === 'v2' ? 'v2Token' : 'token'];
      }

      const headers: { [key: string]: string | undefined } = {
        recaptcharesponsetoken: token,
      };
      if (access.token) {
        headers.Authorization = `Bearer ${access.token}`;
      }
      // debugger;
      const res: any = await axiosServer.post(
        charityRunHouseholdUrl,
        charityRun,
        { headers }
      );

      await dispatch(setContactId(res.contactId));
      await dispatch(setAccountId(res.accountId));
      await dispatch(setRecaptchaUsed());

      return res;
    } catch (e) {
      console.error(e);
      await dispatch(setRecaptchaUsed());
      return rejectWithValue(e);
    }
  }
);

export const validateAttendee = createAsyncThunk<
  any,
  number,
  { state: RootState }
>("charityRun/updateAttendee", async (index, { getState, rejectWithValue }) => {
  const {
    charityRun: { attendees },
  } = getState();
  try {
    await attendeeSchema.validate(attendees[index]);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const setReady = createAsyncThunk<
  boolean,
  boolean,
  { state: RootState; extra: Extra }
>("charityRun/setReady", async (ready, { dispatch }) => {
  const defaultOptions = {
    opto: "35",
    show: "one-time",
    term: "one-time",
    amount: "35",
  };

  if (ready) {
    console.log("---->>>RESETTING PLANS", defaultOptions);
    await dispatch(setPlans(undefined, undefined, defaultOptions.opto));
    await dispatch(
      setInitialAmountType({
        amount: defaultOptions.amount,
        type: defaultOptions.term,
        onlyShowTypes: [defaultOptions.show],
      })
    );
  }

  return ready;
});

export const addAttendee = createAsyncThunk<
  CharityRunContact,
  void,
  { state: RootState; extra: Extra }
>("charityRun/addAttendee", async (_, { getState, dispatch }) => {
  const {
    charityRun: { attendees },
  } = getState();
  const attendee: CharityRunContact = {
    firstName: "",
    lastName: "",
    shirtSize: "xs",
    email: "",
  };

  if (attendees.length > 0) {
    // add another 35 for new attendee
    const options = {
      show: "one-time",
      term: "one-time",
      amount: ((attendees.length + 1) * 35).toString(),
    };

    dispatch(setPlans(undefined, undefined, options.amount));
    dispatch(
      setInitialAmountType({
        amount: options.amount,
        type: options.term,
        onlyShowTypes: [options.show],
      })
    );
  }

  return attendee;
});

export const removeAttendee = createAsyncThunk<
  number,
  number,
  { state: RootState; extra: Extra }
>("charityRun/removeAttendee", async (removeIndex, { getState, dispatch }) => {
  const {
    charityRun: { attendees },
  } = getState();

  if (attendees.length !== 1) {
    const options = {
      show: "one-time",
      term: "one-time",
      amount: ((attendees.length - 1) * 35).toString(),
    };

    dispatch(setPlans(undefined, undefined, options.amount));
    dispatch(
      setInitialAmountType({
        amount: options.amount,
        type: options.term,
        onlyShowTypes: [options.show],
      })
    );
  }

  return removeIndex;
});

const charityRunSlices = createSlice({
  name: "charityRun",
  initialState,
  reducers: {
    setCampaignId(state, { payload }: PayloadAction<string>) {
      state.campaignId = payload;
    },
    updateAttendee(
      state,
      {
        payload: [index, target],
      }: PayloadAction<[number, { name: string; value: string }]>
    ) {
      state.attendees[index][target.name] = target.value;
    },
    updateConfirmed(state, { payload }: PayloadAction<boolean>) {
      state.confirmed = payload;
    },
    updateExtraForms(
      state,
      {
        payload: [name, value],
      }: PayloadAction<[string, any]>
    ) {
      state.extraForms[name] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCharityRunValidity.pending, (state) => {
      state.__pristine = false;
    });
    builder.addCase(checkCharityRunValidity.rejected, (state, { payload }) => {
      state.__errors = transformErrorIntoObject(payload);
      state.__valid = false;
      console.log(
        "[slices][charityRun] checkCharityRunValidity results",
        state.__errors
      );
    });
    builder.addCase(checkCharityRunValidity.fulfilled, (state) => {
      state.__errors = [];
      state.__valid = true;
    });

    builder.addCase(validateAttendee.rejected, (state, { payload }) => {
      state.__errors = transformErrorIntoObject(payload);
      state.__valid = false;
      console.log(
        "[slices][charityRun] validateAttendee results",
        state.__errors
      );
    });

    builder.addCase(
      setReady.fulfilled,
      (state, { payload }: PayloadAction<boolean>) => {
        state.ready = payload;
      }
    );

    builder.addCase(
      addAttendee.fulfilled,
      (state, { payload }: PayloadAction<CharityRunContact>) => {
        state.attendees.push(payload);
      }
    );

    builder.addCase(
      removeAttendee.fulfilled,
      (state, { payload }: PayloadAction<number>) => {
        state.attendees.splice(payload, 1);
      }
    );
  },
});

export const { setCampaignId, updateAttendee, updateConfirmed, updateExtraForms } =
  charityRunSlices.actions;
export default charityRunSlices.reducer;
